.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.title {
  font-family: "DINCondensed";
  font-size: 2.3em;
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
}

.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: #fff;
  margin-bottom: 1em;
  width: 30%;
  text-align: center;
}

@media (max-width: 768px) {
  div.subtitle {
    width: 80%;
  }
}

.header {
  font-family: "DINCondensed";
  font-size: 2em;
  font-weight: lighter;
  text-align: center;
  margin-bottom: 1rem;
  background-color: white;
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8em;
  color: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 0.6em;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  display: inline-block;
  width: 200px;
}

.button-facebook {
  color: #453b8d;
  border-color: #5873ae;
  box-shadow: 0 0 40px 40px #fff inset, 0 0 0 0 #fff;
  transition: all 150ms ease-in-out;
}

.button-google {
  color: #fff;
  border-color: #5c9fef;
  box-shadow: 0 0 40px 40px #5c9fef inset, 0 0 0 0 #5c9fef;
  transition: all 150ms ease-in-out;
}

.button-bad {
  color: #453b8d;
  border-color: #fff;
  box-shadow: 0 0 40px 40px #fff inset, 0 0 0 0 #fff;
  transition: all 150ms ease-in-out;
}

.button-great {
  color: #453b8d;
  border-color: #4ff6c6;
  box-shadow: 0 0 40px 40px #4ff6c6 inset, 0 0 0 0 #4ff6c6;
  transition: all 150ms ease-in-out;
}

.button-send {
  color: #453b8d;
  border-color: #4ff6c6;
  box-shadow: 0 0 40px 40px #4ff6c6 inset, 0 0 0 0 #4ff6c6;
  transition: all 150ms ease-in-out;
}

.btn:hover {
  box-shadow: 0 0 40px 40px #252531 inset, 0 0 0 0 #252531;
  border-color: #252531;
  color: #fff;
}

form {
  text-align: center;
}

textarea {
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #555555;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}

textarea:focus {
  background: none repeat scroll 0 0 #ffffff;
  outline-width: 0;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

a.disabled {
  pointer-events: none;
  cursor: default;
}